import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { DateUtil } from '@utils/classes/DateUtil';
import { ScaOrdenManifiestoOcResu, ScaOrdenManifiestoResu, ScaOrdenManifiestoRoDetResu, ScaOrdenManifiestoRoResu } from '~models/carga/dto/resumen/ScaManifiestoResu';
import { ScaOrdenCargaDto, ScaRoutingDto } from '~models/carga/interface';
import { ScaOrdenManifiesto } from '~models/carga/interface/ScaOrdenManifiesto';
import { DateFormat } from '~models/Util';

export interface ScaOrdenManifiestoCrudState {
	scaOrdenManifiesto: ScaOrdenManifiesto | null | undefined;
	scaOrdenManifiestoUpd: ScaOrdenManifiesto | null | undefined;
	scaOrdenManifiestoResu: ScaOrdenManifiestoResu | null | undefined;
}
export const initialState: ScaOrdenManifiestoCrudState = {
	scaOrdenManifiesto: null,
	scaOrdenManifiestoUpd: null,
	scaOrdenManifiestoResu: null,
};

@Injectable()
export class OrdenManifiestoStore extends ComponentStore<ScaOrdenManifiestoCrudState> {
	private _dateUtil = new DateUtil();

	constructor() {
		super(initialState);
	}
	//#region OBSERVABLES
	readonly getScaOrdenManifiesto$ = this.select((state) => state.scaOrdenManifiesto);
	readonly getScaOrdenManifiestoUpd$ = this.select((state) => state.scaOrdenManifiestoUpd);
	readonly getScaOrdenManifiestoResu$ = this.select((state) => state.scaOrdenManifiestoResu);
	//#endregion

	//#region SETTERS
	readonly setScaOrdenManifiesto = this.updater((state, scaOrdenManifiesto: ScaOrdenManifiesto) => ({ ...state, scaOrdenManifiesto }));
	readonly setScaOrdenManifiestoUpd = this.updater((state, newResource: Partial<ScaOrdenManifiesto>) => ({
		...state,
		scaOrdenManifiestoUpd: {
			...state.scaOrdenManifiestoUpd,
			...newResource,
		},
	}));
	readonly setScaOrdenManifiestoResu = this.updater((state, scaOrdenManifiestoResu: ScaOrdenManifiestoResu) => ({ ...state, scaOrdenManifiestoResu }));
	//#endregion

	//#region GETTERS
	getScaOrdenManifiesto(): ScaOrdenManifiesto | null | undefined {
		return this.get().scaOrdenManifiesto;
	}
	getScaRoutingResumenMaritimo(): ScaOrdenManifiestoResu | null | undefined {
		const om = this.get().scaOrdenManifiesto;
		// const adic = ro?.scaOrdenCarga?.encargadoDireccionamiento == 'A' ? 'POR AGENCIA' : 'POR CLIENTE';
		// const date = this._dateUtil.formatDate(ro?.scaOrdenCarga?.fchDireccionamiento ?? null, DateFormat.DATE);

		//console.log('getScaRoutingResumenMaritimo', ro);

		this.setScaOrdenManifiestoResu({
			strManifiestoTitulo: String(om?.nroOrdenManifiesto),
			strFchMdPrevio: this._dateUtil.formatDate(om?.fchEnvioPrevio ?? null, DateFormat.DATE),
			strNroTicketPrevio: [om?.anoManifiestoCarga, om?.ticketEnvioPrevio].filter(Boolean).join('-'),
			strNroManifiesto: String(om?.nroManifiestoCarga ?? ''),
			strNombreNave: om?.tbNave?.nombre + ' V.' + om?.nroViaje,
			strFchEstLlegada: this._dateUtil.formatDate(om?.fchEstimadaLlegada ?? null, DateFormat.DATE),
			strFchEstEmbarque: this._dateUtil.formatDate(om?.fchEstimadaEmbarque ?? null, DateFormat.DATE),
			lengthOc: om?.scaOrdenCargas?.length ?? 0,
			scaOrdenManifiestoOcResus: !om?.scaOrdenCargas
				? []
				: om?.scaOrdenCargas?.map((oc: ScaOrdenCargaDto) => {
						return {
							strNroOrdenCarga: oc.nroOrdenCarga,
							strDetalleM: oc.nroDetalleManifiesto,
							strNroDocumentoTransporteM: oc.nroDocumentoTransporte,
							strLugarEmisionM: [oc?.tbUbicacionComercialEmision?.nombre, oc?.tbUbicacionComercialEmision?.tbPais?.nombre].filter(Boolean).join(', '),
							strFchEmisionM: this._dateUtil.formatDate(oc?.fchEmision ?? null, DateFormat.DATE),
							scaOrdenManifiestoRoResus: !oc?.scaRoutinges
								? []
								: oc?.scaRoutinges?.map((ro: ScaRoutingDto) => {
										return {
											strNroRouting: ro.nroRouting,
											strDetalleM: oc.nroDetalleManifiesto,
											strNroDocumentoTransporteM: oc.nroDocumentoTransporte,
											strLugarEmisionM: [oc?.tbUbicacionComercialEmision?.nombre, oc?.tbUbicacionComercialEmision?.tbPais?.nombre].filter(Boolean).join(', '),
											strFchEmisionM: this._dateUtil.formatDate(oc?.fchEmision ?? null, DateFormat.DATE),
											strDetalleH: ro.nroDetalleManifiesto,
											strNroDocumentoTransporteH: ro.nroDocumentoTransporte,
											strLugarEmisionH: [ro?.tbUbicacionComercialEmision?.nombre, ro?.tbUbicacionComercialEmision?.tbPais?.nombre].filter(Boolean).join(', '),
											strFchEmisionH: this._dateUtil.formatDate(ro?.fchEmision ?? null, DateFormat.DATE),
											strPaisOrigen: [oc?.tbUbicacionComercialOrigen?.tbPais?.codigo, oc?.tbUbicacionComercialOrigen?.tbPais?.nombre].filter(Boolean).join(' - '),
											strPuertoEmbarque: [
												[oc?.tbUbicacionComercialEmbarque?.codigo, oc?.tbUbicacionComercialEmbarque?.nombre].filter(Boolean).join(' - '),
												oc.tbUbicacionComercialEmbarque?.tbPais?.nombre,
											].join(', '),
											strFchEmbarque: this._dateUtil.formatDate(oc?.fchEstimadaEmbarque ?? null, DateFormat.DATE),
											strPuertoLlegada: [
												[oc?.tbUbicacionComercialLlegada?.codigo, oc?.tbUbicacionComercialLlegada?.nombre].filter(Boolean).join(' - '),
												oc.tbUbicacionComercialLlegada?.tbPais?.nombre,
											].join(', '),
											strDestinoFinal: [
												[ro?.tbUbicacionComercialDestino?.codigo, ro?.tbUbicacionComercialDestino?.nombre].filter(Boolean).join(' - '),
												ro.tbUbicacionComercialDestino?.tbPais?.nombre,
											].join(', '),
											strDestinacionCarga: [ro.tbTipoDestinacion?.codigo, ro.tbTipoDestinacion?.nombre].filter(Boolean).join(' - '),
											strEmbarcador: ro?.tbEntidadEmbarcador?.razonSocial,
											strEmbarcadorTipoDcto: [ro?.tbEntidadEmbarcador?.tbTipoDocumento?.nombre, ro?.tbEntidadEmbarcador?.nroDocumento].filter(Boolean).join(': '),
											strEmbarcadorDireccion: ro?.tbEntidadEmbarcador?.direccion,
											boolEndoseToOrder: ro?.endoseToOrder,
											strConsignatario: ro?.tbEntidadConsignatario?.razonSocial,
											strConsignatarioTipoDcto: [ro?.tbEntidadConsignatario?.tbTipoDocumento?.nombre, ro?.tbEntidadConsignatario?.nroDocumento].filter(Boolean).join(': '),
											strConsignatarioDireccion: ro?.tbEntidadConsignatario?.direccion,
											strNotificante: ro?.tbEntidadNotificante?.razonSocial,
											strNotificanteTipoDcto: [ro?.tbEntidadNotificante?.tbTipoDocumento?.nombre, ro?.tbEntidadNotificante?.nroDocumento].filter(Boolean).join(': '),
											strNotificanteDireccion: ro?.tbEntidadNotificante?.direccion,
											strAlmacen: oc.tbDepositoFinal?.tbEntidad?.razonSocial,
											strAlmacenTipoOperador: [oc.tbDepositoFinal?.tbTipoOperador?.codigo, oc.tbDepositoFinal?.tbTipoOperador?.nombre].filter(Boolean).join(' - '),
											strAlmacenTipoDcto: [oc.tbDepositoFinal?.tbEntidad?.tbTipoDocumento?.nombre, oc.tbDepositoFinal?.tbEntidad?.nroDocumento].filter(Boolean).join(': '),
											strAlmacenDireccion: oc.tbDepositoFinal?.tbEntidad?.direccion,
											strLocalAnexo: [oc.tbEntidadLocal?.codigo, oc.tbEntidadLocal?.nombre].filter(Boolean).join(' - '),
											strLocalAnexoDireccion: [
												oc.tbDepositoFinal?.tbEntidad?.direccion,
												oc.tbDepositoFinal?.tbEntidad?.tbUbicacionGeografica?.idUbigeoPadre?.nombre,
												oc.tbDepositoFinal?.tbEntidad?.tbUbicacionGeografica?.idUbigeoPadre?.idUbigeoPadre?.nombre,
											]
												.filter(Boolean)
												.join(', '),
											strTerminalPortuario: oc.tbTerminalPortuario?.tbEntidad?.razonSocial,
											scaOrdenManifiestoRoDetResus: ro.scaRoutingDetalles?.map((d) => {
												return {
													boolPeligrosidad: d.tbMercanciaPeligrosa ?? false,
													strIdentificadorPeligrosidad: [d.tbMercanciaPeligrosa?.codigo, d.tbMercanciaPeligrosa?.nombre].filter(Boolean).join(' - '),
													strNaturalezaCarga: [d.tbNaturalezaCarga?.codigo, d.tbNaturalezaCarga?.nombre].filter(Boolean).join(' - '),
													strCondicionCarga: [d.tbCondicionCarga?.codigo, d.tbCondicionCarga?.nombre].filter(Boolean).join(' - '),
													strIncoterm: ro.tbIncoterm?.codigo,
													strBultos: [d.cantidadBultos, d.tbTipoBulto?.nombre].filter(Boolean).join(' - '),
													strPesoBruto: [d.pesoBruto, d.tbUnidadMedidaPeso?.nombre].filter(Boolean).join(' - '),
													strVolumen: [d.volumen, d.tbUnidadMedidaVolumen?.nombre].filter(Boolean).join(' - '),
													strNroContenedorAsociados: d.scaRoutingDetalleContenedores?.map((c) => c.scaRoutingContenedor?.nroContenedor)?.join(' | '),
													strTipoEnvio: ro.tbTipoEnvio?.nombre,
													strMarcas: d.marcasNumeros,
													strDescripcionMercancia: d.descripcionMercancia,
												} as ScaOrdenManifiestoRoDetResu;
											}),
											boolAcogimientoTransbordo: ro.tbTipoDestinacion?.codigo === '28',
											strOperadorPortuario: [ro.tbTerminalPortuarioSalida?.tbEntidad?.nroDocumento, ro?.tbTerminalPortuarioSalida?.tbEntidad?.razonSocial].filter(Boolean).join(' - '),
											strViaTransporteSalida: [oc.tbViaTransporte?.idViaTransporte, oc.tbViaTransporte?.nombre].filter(Boolean).join(' - '),
											strNaveSalida: [ro.tbNaveSalida?.codigo, ro.tbNaveSalida?.nombre].filter(Boolean).join(' - '),
											strModalidadTransbordo: [ro.tbModalidadTransbordo?.codigo, ro.tbModalidadTransbordo?.nombre].filter(Boolean).join(' - '),
											strTransportistaSalida: [ro.tbTransportistaSalida?.tbEntidad?.nroDocumento, ro.tbTransportistaSalida?.tbEntidad?.razonSocial].filter(Boolean).join(' - '),
											strTipoLugarSalida: [ro?.tbTerminalPortuarioSalida?.tbTipoTerminalPortuario?.codigo, ro?.tbTerminalPortuarioSalida?.tbTipoTerminalPortuario?.nombre].filter(Boolean).join(' - '),
											strNroMatriculaVehiculo: ro.scaRoutingDetalles?.map((mat) => mat.nroMatriculaVehiculo).join(', '),
										} as ScaOrdenManifiestoRoResu;
								  }),
						} as ScaOrdenManifiestoOcResu;
				  }),
		});

		return this.get().scaOrdenManifiestoResu;
	}

	//#endregion
}
