import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ApiResponse } from '@utils/models/ApiResponse';
import { map, shareReplay } from 'rxjs/operators';
import { SearchControlContenedoresDto } from '~models/carga/dto/SearchControlContenedoresDto';
import { UpdateScaRoutingContenedorDto } from '~models/carga/dto/UpdateScaRoutingContenedorDto';
import { ScaRoutingControlContenedor } from '~models/carga/projection';
import { Observable } from 'rxjs';
import { ScaRoutingContenedorDto } from '~models/carga/interface/ScaRoutingContenedor';

@Injectable({
	providedIn: 'root',
})
export class ScaRoutingContenedorService {
	private readonly _url = `${environment.HOST_CARGA}/scaroutingcontenedores`;
	//private readonly urlTmp = `http://localhost:8092/sumax-erp-backend-carga/api/scaroutingcontenedores`;

	constructor(private http: HttpClient) {}

	// updateCustom(scaRoutingContenedores: UpdateScaRoutingContenedorDto): Observable<ApiResponse<void>> {
	// 	return this.http.put<ApiResponse<void>>(`${this._url}/updateCustom`, scaRoutingContenedores);
	// }

	findById(idContenedor: number): Observable<ScaRoutingContenedorDto> {
		return this.http.get<ApiResponse<ScaRoutingContenedorDto>>(`${this._url}/model-se-ro/${idContenedor}`).pipe(map((res) => res.data));
	}

	findAllBySearchCriteria(search: SearchControlContenedoresDto): Observable<ScaRoutingControlContenedor[]> {
		return this.http.post<ApiResponse<ScaRoutingControlContenedor[]>>(`${this._url}/model-se-ro/findAllBySearchCriteria`, search).pipe(
			map((res) => res.data),
			shareReplay(1)
		);
	}

	countByIdRouting(iRouting: number): Observable<number> {
		return this.http.get<ApiResponse<number>>(`${this._url}/countByIdRouting/${iRouting}`).pipe(map((res) => res.data));
	}

	updateDateCustom(scaRoutingContenedores: UpdateScaRoutingContenedorDto): Observable<ScaRoutingControlContenedor[]> {
		return this.http.put<ApiResponse<ScaRoutingControlContenedor[]>>(`${this._url}/updateDateCustom`, scaRoutingContenedores).pipe(map((resp) => resp.data));
	}
}
