import { Decimal } from 'decimal.js';

const regExpComma = /,/g;
const regExpDot = /./g;

export function toNumber(n: any): number {
	const number = new Number(n);
	return number.valueOf();
}

export function formatDecimal(value: string | number, decimals: number): number {
	//Leer locale de sistema
	if (typeof value != 'undefined' && typeof decimals != 'undefined') {
		value = value.toString();
		return +Number(value.replace(regExpComma, '').replace(regExpDot, '')).toFixed(decimals);
	}
	return 0;
}

/**
 * Método para convertir numeros enteros a decimal
 * @param value
 * @param decimals cantidad de decimales
 * @returns
 */
export function convertToDecimal(value: string | number, decimals = 3): string {
	if (value) {
		if (typeof value === 'number') {
			value = value ? value : 0;
		} else {
			value = value ? parseFloat(value) : 0;
		}
		const res1: string = value.toFixed(decimals);
		const res2: string = res1.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
		return res2;
	}

	return '0';
}

export function subtract(minuendo: number, sustraendo: number): number {
	minuendo = toNumber(minuendo);
	sustraendo = toNumber(sustraendo);
	const min = new Decimal(minuendo);
	const result = min.minus(new Decimal(sustraendo));
	return result.toNumber();
}

export function multiply(numbers: number[]): number {
	let result = new Decimal(1);
	numbers.forEach((number) => {
		const n = toNumber(number);
		if (typeof n != 'undefined') {
			result = result.mul(new Decimal(n));
		}
	});
	return result.toNumber();
}

export function divide(dividendo: number, divisor: number): number {
	dividendo = toNumber(dividendo);
	divisor = toNumber(divisor);
	if (divisor > 0 && !isNaN(dividendo) && !isNaN(divisor)) {
		const divid = new Decimal(dividendo);
		const result = divid.div(new Decimal(divisor));
		return result.toNumber();
	} else {
		return 0;
	}
}

export class MathUtil {
	regExpComma = /,/g;
	regExpDot = /./g;

	formatDecimal(value: string | number, decimals: number): number {
		//Leer locale de sistema
		if (typeof value != 'undefined' && typeof decimals != 'undefined') {
			value = value.toString();
			return +Number(value.replace(this.regExpComma, '').replace(this.regExpDot, '')).toFixed(decimals);
		}
		return 0;
	}

	/**
	 * Método para convertir numeros enteros a decimal
	 * @param value
	 * @param decimals cantidad de decimales
	 * @returns
	 */
	convertToDecimal(value: string | number, decimals = 3): string {
		if (value) {
			if (typeof value === 'number') {
				value = value ? value : 0;
			} else {
				value = value ? parseFloat(value) : 0;
			}
			const res1: string = value.toFixed(decimals);
			const res2: string = res1.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
			return res2;
		}

		return '0';
	}

	/**@deprecated nuevo método formatDecimal() */
	formatDecimalDot(value: string | number, decimals: number): number {
		if (typeof value != 'undefined' && typeof decimals != 'undefined') {
			value = value.toString();
			return +Number(value.replace(this.regExpDot, '')).toFixed(decimals);
		}

		return 0;
	}

	/**@deprecated nuevo método formatDecimal() */
	decimalFormatter(params: number): string {
		return params.toFixed(2);
	}

	plus(numbers: number[]) {
		let result = new Decimal(0);
		numbers.forEach((number) => {
			const n = this.toNumber(number);
			if (typeof n != 'undefined') {
				result = result.plus(new Decimal(number));
			}
		});
		return result.toNumber();
	}

	subtract(minuendo: number, sustraendo: number) {
		minuendo = this.toNumber(minuendo);
		sustraendo = this.toNumber(sustraendo);
		const min = new Decimal(minuendo);
		const result = min.minus(new Decimal(sustraendo));
		return result.toNumber();
	}

	multiply(numbers: number[]) {
		let result = new Decimal(1);
		numbers.forEach((number) => {
			const n = this.toNumber(number);
			if (typeof n != 'undefined') {
				result = result.mul(new Decimal(n));
			}
		});
		return result.toNumber();
	}

	divide(dividendo: number, divisor: number) {
		dividendo = this.toNumber(dividendo);
		divisor = this.toNumber(divisor);
		if (divisor > 0 && !isNaN(dividendo) && !isNaN(divisor)) {
			const divid = new Decimal(dividendo);
			const result = divid.div(new Decimal(divisor));
			return result.toNumber();
		} else {
			return 0;
		}
	}

	toNumber(n: any): number {
		const number = new Number(n);
		return number.valueOf();
	}
}

export function parseDecimalAndCommaCustom(numero: number, decimal = 2): string {
	if (numero == null) return '';
	const d = parseFloat(String(numero)).toFixed(decimal);
	return Number(d).toLocaleString('en', {
		minimumFractionDigits: decimal,
		maximumFractionDigits: decimal,
	});
}
